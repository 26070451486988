<template>
  <div>
    <div class="recipe">
      <div class="recipe__figure">
        <img
          :src="itemData.featured_picture"
          alt="food's photo">
      </div>
      <div class="recipe__info">
        <h1 class="recipe__title">
          {{ itemData.name }}
        </h1>
        <span class="recipeLikes">
          <span>
            <button
              @click="likeProduct()"
              class="transparentButton"
              :disabled="!$store.getters.userLoggedIn"
              :title="$store.getters.userLoggedIn ? 'Love it':'Please login to give it some love'"
            >
              <i
                class="fa-heart"
                :class="itemData.user_upvoted ? 'fas likeAnimation' : 'fal'"
              />
            </button>
            <div
              class="itemCard__likeCounter"
              style="margin-right: 10px"
              :class="{ 'itemCard__likeCounter--liked': itemData.user_upvoted }"
            >
              <p>{{ itemData.upvotes }}</p>
              <p>{{ itemData.upvotes }}</p>
            </div>
            <!-- <button
              disabled
              class="transparentButton"
              title="No comments yet">
              <i class ="fal fa-comment"/>
            </button>
            <div
              class="itemCard__likeCounter"
            >
              <p>0</p>
            </div> -->
          </span>
        </span>
        <hr v-if="itemData.owner">
        <div v-if="itemData.owner">
          By
          <router-link :to="`/o/${itemData.owner.slug}`" style="color: black;">
            {{ itemData.owner.name }}
          </router-link>
        </div>
        <hr>
        <p class="recipe__desc">
          “{{ itemData.description }}”
        </p>
        <hr>
        <div class="recipe__detail">
          <span class="recipe__detail-key">Servings</span>
          <span class="recipe__detail-value">{{ itemData.servings }}</span>
        </div>
        <div class="recipe__detail" v-if="itemData.preparation_time">
          <span class="recipe__detail-key">Preparation Time</span>
          <span
            class="recipe__detail-value">{{ itemData.preparation_time }} min</span>
        </div>
        <div class="recipe__detail" v-if="itemData.cooking_time">
          <span class="recipe__detail-key">Cooking Time</span>
          <span
            class="recipe__detail-value">{{ itemData.cooking_time }} min</span>
        </div>
        <div class="recipe__detail" v-if="itemData.total_time && (itemData.preparation_time && itemData.cooking_time)">
          <span class="recipe__detail-key">Total Time</span>
          <span
            class="recipe__detail-value">{{ itemData.total_time }} min</span>
        </div>
        <div class="recipe__detail">
          <span class="recipe__detail-key">Difficulty level</span>
          <span class="recipe__detail-value">{{
            convertDifficultyLevel(itemData.difficulty_level)
          }}</span>
        </div>
        <hr v-if="itemData.cuisines && itemData.cuisines.length > 0">
        <div class="recipe__detail" v-if="itemData.cuisines && itemData.cuisines.length > 0">
          <span class="recipe__detail-key">Cuisine</span>
          <span
            v-for="cuisine in itemData.cuisines"
            :key="cuisine"
            class="recipe__detail-value"> {{ cuisine }}</span>
        </div>
        <hr v-if="itemData.cooking_methods && itemData.cooking_methods.length > 0">
        <div class="recipe__detail" v-if="itemData.cooking_methods && itemData.cooking_methods.length > 0">
          <span class="recipe__detail-key">Cooking Methods</span>
          <span
            v-for="method in itemData.cooking_methods"
            :key="method"
            class="recipe__detail-value"> {{ method }}</span>
        </div>
        <hr>
        <div class="recipe__tags">
          <img
            class="recipe__tag-icon"
            draggable="false"
            :src="TagIcon"
            alt="icon">

          <router-link
            v-for="tag in itemData.tags"
            v-bind:key="`/tag/${tag}/`"
            class="recipe__tag"
            :to="`/tag/${tag}/`">
            {{ tag }}
          </router-link>
        </div>
        <hr>
        <div class="recipe__nutritions">
          <div class="recipe__nutrition-list">
            <!-- Nutrition item -->
            <div class="recipe__nutrition">
              <div class="recipe__nutrition-info">
                <span class="recipe__nutrition-info-title">Calories</span>
                <p class="recipe__nutrition-info-desc">
                  Calories are just a unit of energy. If you eat more than you
                  use you can gain weight, or lose it if you don't eat enough.
                  How much you need depends on your weight, gender and how
                  active you are, but it's around 2,000 a day.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="recipe-info">
      <div class="ingredients">
        <span class="ingredients__title">Ingredients</span>
        <ul class="ingredients__list">
          <li
            v-for="ingredient in itemData.ingredients"
            :key="ingredient.name"
            class="ingredients__item">
            <b>{{ upperCaseFirstLetter(ingredient.name) }}</b> {{ ingredient.quantity }} {{ ingredient.unit !== 'qt' ? ingredient.unit : '' }}
          </li>
        </ul>
      </div>

      <div class="method">
        <span class="method__title">Method</span>
        <p
          class="method__item"
          v-html="itemData.content" />
      </div>
    </div>

    <div class="related-products">
      <h2>
        Related Products
      </h2>
      <div
        class="relatedProducts"
        v-if="itemData.owner && itemData.owner.id.includes('org_')"
      >
        <Listing
          :ids="relatedProductItems.map(item => item.id)"
          :userLogged="$store.getters.userLoggedIn"
          :ignoreVeganFlag="true"
          :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
          :disabledCollections="['vk_icecream']"
          @addItemToCart="$emit('addItemToCart', $event)"
          @openSnackbar="$emit('openSnackbar', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from '@/services/httpClient'
import TagIcon from '@/assets/price-tag.svg'
import siteConstants from '@/const.js'
import Utils from '../utils'

export default {
  name: 'RecipePage',
  data: () => ({
    siteConstants,
    TagIcon,
    relatedProductItems: [],
    itemData: {}
  }),
  computed: {
    slug () {
      if (Utils.cleanSlug(this.$route.params.slug) !== this.$route.params.slug) {
        this.$router.replace(`/recipe/${Utils.cleanSlug(this.$route.params.slug.toLowerCase())}`)
      }
      return this.$route.params.slug
    }
  },
  async created () {
    if (Utils.containsUppercase(this.slug)) {
      // contains upper case => replace to lowercase, everything else
      // should be updated/computed automatically
      const cleanSlug = Utils.cleanSlug(this.slug.toLowerCase())
      console.log(cleanSlug)
      this.$router.replace(`/recipe/${cleanSlug}`)
      return
    }
    await httpClient.get(`/item/${this.slug}`).then((response) => {
      if (response.data.item_type === 'article') {
        this.$router.replace(`/mag/${this.$route.params.slug}`)
      }
      this.itemData = response.data
      this.productLoaded = true
      this.getRelatedProducts()
    })
  },
  methods: {
    convertDifficultyLevel (level) {
      switch (level) {
        case 1:
          return 'Easy'
        case 2:
          return 'Medium'
        case 3:
          return 'Hard'
        default:
          return ''
      }
    },
    upperCaseFirstLetter (text) {
      return (text.charAt(0).toUpperCase() + text.slice(1))
    },
    getRelatedProducts () {
      httpClient
        .get(`/org/${this.itemData.owner.id}/items?format=full`)
        .then((res) => {
          this.relatedProductItems = res.data
        })
    },
    async likeProduct () {
      if (this.itemData.user_upvoted) {
        try {
          this.itemData.user_upvoted = false
          this.itemData.upvotes--
          await httpClient.delete(`/item/${this.itemData.id}/upvote`)
        } catch {
          this.itemData.upvotes++
          this.itemData.user_upvoted = true
        }
      } else {
        try {
          this.itemData.user_upvoted = true
          this.itemData.upvotes++
          await httpClient.post(`/item/${this.itemData.id}/upvote`)
            .then(() => {
              this.$emit('openSnackbar', { message: '10 Points received', type: 'info' })
            })
        } catch {
          this.itemData.upvotes--
          this.itemData.user_upvoted = false
        }
      }
    }
  },
  metaInfo () {
    if (!this.itemData.name) return {}
    const jsonld = {
      '@context': 'http://schema.org/',
      name: this.itemData.name,
      type: 'Recipe',
      description: this.itemData.description,
      image: this.itemData.pictures,
      recipeIngredient: this.itemData.ingredients && this.itemData.ingredients.map((ingre) => {
        return `${ingre.quantity} ${ingre.name}`
      }),
      recipeInstructions: this.itemData.content && this.itemData.content
        .split('\n')
        .filter(val => val)
        .map((instruct) => ({
          '@type': 'HowToStep',
          'text': instruct
        })),
      prepTime: `PT${this.itemData.preparation_time}M`,
      cookTime: `PT${this.itemData.preparation_time}M`,
      author: this.itemData.owner && {
        '@type': 'Person',
        'name': this.itemData.owner.name
      },
      keywords: this.itemData.tags,
      aggregateRating: {
        '@type': 'AggregateRating',
        'ratingValue': 5,
        'ratingCount': this.itemData.upvotes || 1
      }
    }

    return {
      title: this.itemData.name,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.itemData.tags.join`,` },
        // OpenGraph data
        { property: 'og:title', content: this.itemData.name + ` - ${siteConstants.siteName}` },
        { property: 'og:site_name', content: `${siteConstants.siteName}` },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${siteConstants.siteUrl}/` + this.itemData.slug },
        { property: 'og:image', content: this.itemData.featured_picture },
        { property: 'og:description', content: this.itemData.description },
        // Twitter card
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.itemData.name + ` - ${siteConstants.siteName}` },
        { name: 'twitter:description', content: this.itemData.description },
        { name: 'twitter:image:src', content: this.itemData.featured_picture },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.itemData.name + ` - ${siteConstants.siteName}` },
        { itemprop: 'description', content: this.itemData.description },
        { itemprop: 'image', content: this.itemData.featured_picture }
      ],
      script: [{
        type: 'application/ld+json',
        json: jsonld
      }]
    }
  }
}
</script>

<style lang="stylus" scoped>
.recipe {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 30px;
  text-align: left;
  padding: 40px 50px 15px 50px;

  @media screen and (max-width: 768px) {
    padding: 10px 30px 15px 30px;
  }

  @media only screen and (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .recipeLikes {
    margin-top auto
    // padding 30px 5px 10px 5px
    width 100%
    display flex
    align-items center
    justify-content space-between
    user-select none

    .itemCard {
      &__likeCounter {
        margin-left 4px
        display inline-block
        width auto
        height 21px
        overflow hidden
        p {
          margin 0
          transform translateY(1px)
          transition 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
          padding 0
        }
        &--liked {
          p {
            transform translateY(-18px)
          }
        }
      }
      &__like {
        display flex
        align-items center
        button {
          display block
          cursor pointer
          width 70px
          height 70px
          background transparent
          outline none !important
          border 1px solid rgba(179,179,179,0.4)
          font-size 16px
          &[disabled] {
            cursor default
            color inherit
          }
          i {
            opacity .4
          }
        }
        &--liked {
          button {
            i {
              opacity 1
            }
          }
        }
      }
    }

    .transparentButton {
      border none
      cursor pointer
      background transparent
      padding 0
      font inherit

      &[disabled] {
        cursor default
        color #999
      }
    }

    span {
      margin-right: 9px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__figure {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media only screen and (max-width: 750px) {
      align-items: center;
    }

    & img {
      margin-bottom: 15px;
      width: 400px;

      @media only screen and (max-width: 400px) {
        width: 100%;
      }
    }
  }

  &__btn {
    font-size: 12px;
    padding: 8px 10px;
    cursor: pointer;
    font-family: inherit;
    font-weight: bold;
    background-color: #62a5a1;
    border: none;
    color: #fff;
    border-radius: 3px;
    line-height: 1;
  }

  &__title {
    margin: 0 0 10px 0;
    font-family: Cabin, sans-serif
  }

  &__subtitle {
    color: #a3865b;
    text-transform: uppercase;
    margin: 0 0 15px 0;
  }

  &__category {
    background-color: #62a5a1;
    color: #fff;
    font-size: 13px;
    padding: 10px 15px;
    display: inline-block;
    line-height: 1;
    text-transform: uppercase;
  }

  & hr {
    margin: 15px 0;
    border: none;
    border-top: 1px solid #878787;
  }

  &__detail {
    display: flex;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__detail-key {
    margin-right: 5px;
    color: #a3865b;
  }

  &__tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__tag-icon {
    height: 23px;
    margin-right: 10px;
  }

  &__tag {
    color: #62a5a1;
    margin: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: #000;
    }

    &::after {
      content: ',';
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  &__nutritions {
    display: flex;
    flex-direction: column;
  }

  &__nutritions-text {
    font-size: 11px;
    text-transform: uppercase;
    margin: 0;
    font-weight: bold;
  }

  &__nutrition-list {
    display: flex;
    margin: 10px 0;
  }

  &__nutrition {
    position: relative;
  }

  &__nutrition-item {
    padding: 6px;
    border: 1px solid #000;
    border-radius: 3px;
    max-width: max-content;
    min-width: 65px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;

    &:hover + .recipe__nutrition-info {
      display: block;
    }

    & hr {
      margin: 5px 0;
      border: none;
      border-top: 1px solid #c2c2c2;
    }
  }

  &__nutrition-item-label {
    font-size: 12px;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  &__nutrition-item-value {
    font-size: 14px;
  }

  &__nutrition-info {
    position: absolute;
    border: 1px solid #a3865b;
    border-radius: 3px;
    padding: 15px 25px 20px 25px;
    margin-top: 10px;
    text-align: center;
    background-color: #fff;
    width: 300px;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    display: none;

    @media only screen and (max-width: 750px) {
      transform: none;
      left: 0;
    }
  }

  &__nutrition-info-title {
    margin: 0 15px 10px 0;
    font-size: 30px;
    border-bottom: 3px solid #c5b9a0;
    padding-bottom: 3px;
    display: inline-block;
  }

  &__nutrition-info-desc {
    margin: 0;
    line-height: 1.6;
  }
}

.recipe-info {
  @extend .recipe;
  margin-top: 20px;
}

.ingredients {
  min-width: 250px;

  &__title {
    font-size: 24px;
    font-style: italic;
    margin-bottom: 10px;
    display: block;
  }

  &__list {
    list-style: none;
    padding: 0;

    & > * + * {
      margin-top: 10px;
    }
  }

  &__item {
    font-size: 17px;
  }
}

.method {
  &__title {
    @extend .ingredients__title;
  }

  &__item {
    font-size: 16px;
    white-space: pre-wrap;
    line-height: 1.7;
  }
}

.related-products {
  padding: 40px 50px 15px 50px;

  h2 {
    font-size: 24px;
    font-style: italic;
    margin-bottom: 20px;
    display: block;
    text-align: center;
    font-family: 'Bebas Neue', sans-serif;
  }

  @media screen and (max-width: 768px) {
    padding: 10px 30px 15px 30px;
  }
}
</style>
